/* eslint no-console:0 */
// This is the main entry point which needs to be required by all pages, because it adds polyfills
// for all ES6 features
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import 'webcrypto-shim'
import 'fastestsmallesttextencoderdecoder/EncoderDecoderTogether.min'

import { Turbo } from '@hotwired/turbo-rails'
Turbo.session.drive = false
